import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPage } from '../../Page';
import Selectors from '../../Selectors';
import ActionCreator from '../../ActionCreator';
import * as Widget from '../../Components/Widget';
import ParseQuery from '../../Utils/ParseQuery';

class SelectCvsPage extends React.Component {
  componentDidMount() {
    let { location, appActions, navActions } = this.props;
    try {
      let queryParams = ParseQuery(location.search);
      let store_id = queryParams.store_id || '';
      let store_name = queryParams.store_name
        ? decodeURIComponent(queryParams.store_name)
        : '';
      console.log('params', queryParams);
      appActions.cart.setData(
        {
          payment_type: 'ecpay_cvs',
          store_id,
          store_name,
        },
        false
      );
      setTimeout(() => {
        navActions.push('/cart');
      }, 1500);
    } catch (err) {
      console.warn(err);
    }
  }

  render() {
    return (
      <SelectCvsPage.Wrapper>
        <Widget.Center>
          <Widget.Spinner />
        </Widget.Center>
      </SelectCvsPage.Wrapper>
    );
  }

  static Wrapper = styled.div`
    max-width: 800px;
    padding: 20px;
    margin: 0 auto;
  `;
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(SelectCvsPage)
);
